<template>
  <v-container fluid  class="fill-height">
   <v-row>
    <v-col cols="12" sm="6" class="pl-10">
      <v-sheet rounded="lg" min-height="268">
      <v-row>
        <v-col cols="12" sm="4">
         <v-img :width="100" aspect-ratio="16/9" cover src="lipachat.png">
         </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="8">
          <h3> Do More on WhatsApp with Lipachat's Whatsapp Messenger </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="8">
          <h4> Don't have an account yet, Sign up today</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="8">
          <v-btn color="#00b4db" class="text-white" to="signup"> Sign Up</v-btn>
        </v-col>
      </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="12" sm="6" class="pr-10">
        <v-form>
        <v-card class="elevation-12">
          <v-toolbar flat>
            <v-row class="pl-5">
              <v-col cols="12" sm="6">
                <h4> Welcome Back</h4>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <v-row v-if="message">
              <v-col cols="12" md="8">
                <p class="text-red"> {{ message }}</p>
              </v-col>
            </v-row>
            <v-form>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field label="Username" v-model="user.username" name="login"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field id="password" label="Password" name="password" v-model="user.password" type="password"></v-text-field>
              </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="#043775" class="white--text" v-on:keyup.enter="login" @click="login">Login</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-col>
   </v-row>
  </v-container>
</template>

<script>
import router from '../router'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Login',
  data: () => ({
    user: {},
    valid: true
  }),
  computed: {
    ...mapState({
      loginError: state => state.session.loginError,
      color: state => state.alert.color,
      timeout: state => state.alert.timeout,
      message: state => state.alert.message
    }),
    snackbar: {
      get: function () {
        return this.$store.state.alert.snackbar
      },
      set: function (newValue) {
        this.$store.state.alert.snackbar = newValue
      }
    },
    containerStyles () {
      return {
        height: `${this.y}px`,
        width: `${this.x}px`
      }
    }
  },
  methods: {
    ...mapActions({
      getToken: 'session/getToken'
    }),
    login () {
      this.loading = true
      this.$store.dispatch('session/resetLoginError', {})
      this.getToken(this.user).then(response => {
        const token = 'Bearer ' + 'testjwt'
        this.$store.dispatch('session/setUser', response.data.user)
        this.$store.dispatch('session/setToken', token)
        if (window.localStorage) {
          window.localStorage.setItem('user', JSON.stringify(response.data.user))
          window.localStorage.setItem('token', token)
        }
        this.$store.dispatch('session/setLoggedIn', true)
        this.loading = false
        router.push(response.data.redirect ? response.data.redirect : '/admin').catch(error => {
          console.log(error)
        })
      }).catch(error => {
        console.log(error)
        this.loading = false
        if (error.response && error.response.status === 401) {
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        } else {
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response ? error.response.data.message : 'Failed to connect to backend' }, { root: true })
        }
      })
    }
  }
}
</script>
